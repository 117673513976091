export class PriceSettingServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getPriceSettingDetail() {
    const { data } = await this.$axios.get(`/PricePerKilometer/Get`);
    const response = data;

    return response;
  }

  async savePriceSetting(payload) {
    const { data } = await this.$axios.post(`/PricePerKilometer/Save`, payload);
    return data;
  }
}
