export class SoldServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getSold(query) {
    const { data } = await this.$axios.get(`/Sold/GetSold`, {
      params: {
        ...query,
      },
    });
    return data;
  }
}
