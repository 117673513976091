const level = 17;
const ProductSetting = {
  path: "",
  name: "",
  redirect: "/package-test-setting",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/package-test-setting",
      redirect: "/package-test-setting",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Product Setting",
      meta: {
        requiresAuth: true,
        activeUrl: "/package-test-setting",
        parent: "/package-test-setting",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/productsetting"),
          meta: {
            requiresAuth: true,
            activeUrl: "/package-test-setting",
            parent: "/package-test-setting",
            lv: level,
          },
        },
        {
          path: "/package-test-setting/detail/:productId/:productType",
          name: "Product Setting Detail",
          component: () => import("@/views/pages/productsetting/detail"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/package-test-setting",
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default ProductSetting;
