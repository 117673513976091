const level = 16;
const PriceSetting = {
  path: "",
  name: "",
  redirect: "/price-setting",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/price-setting",
      redirect: "/price-setting",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Price Setting",
      meta: {
        requiresAuth: true,
        activeUrl: "/price-setting",
        parent: "/price-setting",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/pricesetting"),
          meta: {
            requiresAuth: true,
            activeUrl: "/price-setting",
            parent: "/price-setting",
            lv: level,
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default PriceSetting;
