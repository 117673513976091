const level = 9;
const Branch = {
  path: "",
  name: "",
  redirect: "/branch",
  component: {
    render(c) {
      return c("router-view");
    },
  },
  children: [
    {
      path: "/branch",
      redirect: "/branch",
      component: {
        render(c) {
          return c("router-view");
        },
      },
      name: "Branch List",
      meta: {
        requiresAuth: true,
        activeUrl: "/branch",
        parent: "/branch",
        lv: level,
      },
      children: [
        {
          path: "",
          name: "",
          component: () => import("@/views/pages/branch"),
          meta: {
            requiresAuth: true,
            activeUrl: "/branch",
            parent: "/branch",
            lv: level,
          },
        },
        {
          path: "/createBranch",
          name: "Create Branch",
          component: () => import("@/views/pages/branch/createBranch"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/branch",
          },
        },
        {
          path: "/branch/detail/:id",
          name: "Branch Detail",
          component: () => import("@/views/pages/branch/detail"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/branch",
          },
        },
        {
          path: "/branch/detail/:id/schedule",
          name: "Branch Detail Schedule",
          component: () => import("@/views/pages/branch/detail/calendar"),
          meta: {
            requiresAuth: true,
            lv: level,
            isChildren: true,
            activeUrl: "/branch",
          },
        },
      ],
    },
  ],
  meta: { requiresAuth: true },
};
export default Branch;
