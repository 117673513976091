export class ProductSettingServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getProductLog() {
    const { data } = await this.$axios.get(`/Product/ECommerce/import/LastLog`);
    const response = data;

    return response;
  }

  async importProductFromLIS() {
    const { data } = await this.$axios.post(`/Product/ECommerce/import`);
    const response = data;

    return response;
  }

  async getProductList(params) {
    const { data } = await this.$axios.get(
      `/Product/ECommerce/${params.productType}`,
      { params }
    );
    const response = data;

    return response;
  }

  async getProductTypeList() {
    const { data } = await this.$axios.get(`/Product/ProductType`);
    const response = data;

    return response;
  }

  async getProductDetail(params) {
    const { data } = await this.$axios.get(
      `/Product/ECommerce/${params.productId}/${params.productType}`,
      { params }
    );
    const response = data;

    return response;
  }

  async saveProductSetting(payload) {
    const { data } = await this.$axios.post(`/Product/ECommerce`, payload);
    return data;
  }

  async getAllProductBranch() {
    const { data } = await this.$axios.get(
      `/Product/ECommerce/getAllBranchCode`
    );
    return data;
  }

  async getExportFile(url, params) {
    const { data } = await this.$axios.get(url, {
      params,
      responseType: "blob",
    });
    return data;
  }

  async saveImportFile(url, payload) {
    const formData = new FormData();
    formData.append("file", payload);
    const { data } = await this.$axios.post(url, formData, {
      responseType: "blob",
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    return data;
  }
}
