export class VenueServices {
  constructor($axios) {
    this.$axios = $axios;
  }

  async getVenueList(payload, getEvent) {
    const { data } = await this.$axios.post(`/Venue/List`, payload, {
      params: { getEvent },
    });
    return data;
  }

  async getVenueById(id) {
    const { data } = await this.$axios.get(`/Venue/${id}`);
    return data;
  }

  async saveVenue(payload) {
    const { data } = await this.$axios.post(`/Venue/Save`, { ...payload });
    return data;
  }

  async getRoomByVenueId(id, payload) {
    const { data } = await this.$axios.post(`/Venue/${id}/Room`, {
      ...payload,
    });
    return data;
  }

  async setStatusVenue(id, isActive, setEvent) {
    const { data } = await this.$axios.put(
      `/Venue/${id}/setStatus`,
      {},
      {
        params: { isActive, setEvent },
      }
    );
    return data;
  }

  async saveClient(payload) {
    const { data } = await this.$axios.post(`/Venue/saveClient`, {
      ...payload,
    });
    return data;
  }

  async getSchedule(id) {
    const { data } = await this.$axios.get(`/Venue/GetSchedule/${id}`);
    return data;
  }
}
